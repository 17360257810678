import React, { useEffect, useState } from 'react'

import { Link, navigate } from 'gatsby'
import cn from 'classnames'

import bauerLogo from '~/assets/img/svg/logo-bauer-turismo.svg'
import searchIcon from '~/assets/img/svg/search.svg'
import { menuItems, mobileMenuItems } from '~/data'
import logo from '~/assets/img/logo-footer.png'

import * as st from '~/assets/styl/Menu.module.styl'

export default function Menu({ location }: { location: Location }) {
  const [searchActive, setSearchActive] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [navbarOpen, setNavbarOpen] = useState(false)

  function toggleSearchBar(e) {
    e.preventDefault()
    setSearchActive(true)
  }

  function handleCloseSearchBar(e) {
    e.preventDefault()
    setSearchActive(false)
  }

  function handleSearchChange(e) {
    setSearchValue(e.target.value)
  }

  function handleSubmit(e) {
    e.preventDefault()
    if (searchValue) {
      navigate(`/resultados-busca/${searchValue}`)
      setSearchValue('')
      handleClose()
      setSearchActive(false)
    } else {
      return
    }
  }

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
  }

  const handleClose = () => {
    setNavbarOpen(false)
  }

  const [top, setTop] = useState(true)
  useEffect(() => {
    const onScroll = () => {
      setTop(window.scrollY <= 0)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  })

  return (
    <header
      className={cn(
        st.menuBackground,
        'menu-wrapper',
        top && location.pathname === '/' && st.top
      )}
    >
      <button
        className={cn(st.hamburguerMenu, 'hide-on-desktop')}
        onClick={handleToggle}
      >
        <div className={st.bar1}></div>
        <div className={st.bar2}></div>
        <div className={st.bar3}></div>
      </button>
      <div
        className={cn(st.mobileMenu, 'hide-on-desktop', {
          [st.active]: navbarOpen,
        })}
      >
        <div>
          <nav>
            <button className={st.closeMenu} onClick={handleClose}>
              <span className="icon-left-control"></span>
            </button>
            <ul>
              {mobileMenuItems.map((item, key) => (
                <li key={key}>
                  <Link onClick={handleClose} to={item.slug}>
                    <span className={st.menuItem}>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <img className={st.navLogo} src={logo} alt="Logo do Grupo Bauer" />
      </div>
      <div
        className={cn(
          st.overlay,
          { [st.activeOverlay]: navbarOpen },
          'hide-on-desktop'
        )}
        onClick={handleClose}
      ></div>
      <div>
        <Link to="/">
          <img
            className={st.logo}
            src={bauerLogo}
            alt="Logo da Bauer turismo"
          />
        </Link>
      </div>
      <div className={cn(st.menuNav, 'hide-on-mobile')}>
        <nav>
          <ul className={st.menuItems}>
            {menuItems.map((item, key) => (
              <li key={key}>
                <Link to={item.slug}>
                  <span className={item.icon}></span>
                  <span className={st.menuItem}>{item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <button onClick={toggleSearchBar} type="button">
          <span className="icon-search"></span>
        </button>
      </div>
      <button
        className={cn('hide-on-desktop')}
        onClick={toggleSearchBar}
        type="button"
      >
        <img src={searchIcon} alt="Ícone de uma lupa para pesquisa" />
      </button>
      <div className={cn(st.searchBar, { [st.active]: searchActive })}>
        <form
          onSubmit={(e) => handleSubmit(e)}
          className={cn(st.active, { [st.active]: searchActive })}
        >
          <label>
            <input
              type="text"
              placeholder="o que você procura?"
              value={searchValue}
              onChange={handleSearchChange}
            />
          </label>
          <div className={st.buttonsWrapper}>
            <button className={st.searchButton} type="submit">
              <span className="icon-search"></span>
              <p>Buscar</p>
            </button>
            <button onClick={handleCloseSearchBar}>
              <span className="icon-close"></span>
            </button>
          </div>
        </form>
      </div>
    </header>
  )
}
