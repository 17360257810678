import React from 'react'
import Menu from '~/components/Menu'
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions'
import { PageProps } from 'gatsby'
import WhatsappWidget from '~/components/WhatsappWidget'

const Layout = ({ children, location }: PageProps) => (
  <>
    <Menu location={location} />
    <TransitionProvider
      location={location}
      mode="immediate"
      enter={{
        opacity: 0,
      }}
      usual={{
        opacity: 1,
      }}
      leave={{
        opacity: 0,
        config: {
          duration: 200,
        },
      }}
    >
      <TransitionViews>{children}</TransitionViews>
    </TransitionProvider>
    <WhatsappWidget />
  </>
)

export default Layout
