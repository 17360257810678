import axios from 'axios'

export default (method, path, data = null, config = {}) => {
  const apiUrl = `https://admin.bauerturismo.com/api/${path}`
  return axios[method](apiUrl, data, config)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response && error.response.data) throw error.response.data
      throw error
    })
}

export { default as useApi } from './useApi'
