exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-encontre-seu-guia-tsx": () => import("./../../../src/pages/encontre-seu-guia.tsx" /* webpackChunkName: "component---src-pages-encontre-seu-guia-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-obrigado-tsx": () => import("./../../../src/pages/obrigado.tsx" /* webpackChunkName: "component---src-pages-obrigado-tsx" */),
  "component---src-pages-pacotes-tsx": () => import("./../../../src/pages/pacotes.tsx" /* webpackChunkName: "component---src-pages-pacotes-tsx" */),
  "component---src-pages-pacotes-turisticos-tsx": () => import("./../../../src/pages/pacotes-turisticos.tsx" /* webpackChunkName: "component---src-pages-pacotes-turisticos-tsx" */),
  "component---src-pages-paginas-tsx": () => import("./../../../src/pages/paginas.tsx" /* webpackChunkName: "component---src-pages-paginas-tsx" */),
  "component---src-pages-resultados-busca-tsx": () => import("./../../../src/pages/resultados-busca.tsx" /* webpackChunkName: "component---src-pages-resultados-busca-tsx" */),
  "component---src-pages-servicos-tsx": () => import("./../../../src/pages/servicos.tsx" /* webpackChunkName: "component---src-pages-servicos-tsx" */),
  "component---src-pages-viagem-de-compras-tsx": () => import("./../../../src/pages/viagem-de-compras.tsx" /* webpackChunkName: "component---src-pages-viagem-de-compras-tsx" */)
}

