export default [
  {
    name: 'Pacotes Turísticos',
    slug: '/pacotes-turisticos/',
    icon: 'icon-case',
  },
  {
    name: 'Viagem de Compras',
    slug: '/viagem-de-compras/',
    icon: 'icon-bag',
  },
  {
    name: 'Serviços',
    slug: '/servicos/',
    icon: 'icon-globe',
  },
  {
    name: 'Contato',
    slug: '/contato/',
    icon: 'icon-message',
  },
]
