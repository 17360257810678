import { useState, useEffect } from 'react'
import api from '.'

const useApi = (defaultValue, path) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    api('get', path)
      .then((data) => setValue(data.result || data[path]))
      .catch((err) => console.error(err))
  }, [])

  return value
}

export default useApi
