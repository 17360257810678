import React, { useState } from 'react'
import { useApi } from '~/api'
import cn from 'classnames'
const whatsUrl = 'https://api.whatsapp.com/send'

import * as st from '~/assets/styl/WhatsappWidget.module.styl'

export default function WhatsappWidget() {
  const [isOpen, setIsOpen] = useState(false)
  const whatsapps = useApi([], 'whatsapps')

  function handleClick(e) {
    e.preventDefault()
    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  return (
    <div className={st.whatsappContainer}>
      <div className={cn(st.desktop, 'hide-on-mobile')}>
        <div className={st.whatsappWidget}>
          <span className={`${st.wppIcon} icon-whatsapp`}></span>
          <span>Atendimento WhatsApp</span>
        </div>
        <div className={st.overlay}>
          {whatsapps.map((item, key) => (
            <button key={key}>
              <a
                href={`${whatsUrl}?phone=55${item.phone.replace(/\D/g, '')}`}
                target="_blank"
                rel="noreferrer external nofollow"
                key={key}
              >
                <span className={`${st.atendimento} icon-whatsapp`}></span>
                <p>{item.name}</p>
              </a>
            </button>
          ))}
        </div>
      </div>
      <div className={cn(st.mobile, 'hide-on-desktop')}>
        <div onClick={handleClick} className={st.whatsappWidget}>
          <span className={`${st.wppIcon} icon-whatsapp`}></span>
          <span>Atendimento WhatsApp</span>
        </div>
        <div className={cn(st.overlay, { [st.mobileOverlay]: isOpen })}>
          {whatsapps.map((item, key) => (
            <button key={key}>
              <a
                href={`${whatsUrl}?phone=55${item.phone.replace(/\D/g, '')}`}
                target="_blank"
                rel="noreferrer external nofollow"
                key={key}
              >
                <span className={`${st.atendimento} icon-whatsapp`}></span>
                <p>{item.name}</p>
              </a>
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}
