import earthSvg from '~/assets/img/svg/earth.svg'
import sofaSvg from '~/assets/img/svg/sofa.svg'
import shirtSvg from '~/assets/img/svg/shirt.svg'
import mugSvg from '~/assets/img/svg/mug.svg'

export default [
  {
    icon: earthSvg,
    alt: 'Ícone do planeta terra',
    title: 'Internet Wifi',
    description:
      'Oferecemos Internet Wifi para nossos clientes.\nMais conectividade para você',
  },
  {
    icon: sofaSvg,
    alt: 'Ícone de um sofa',
    title: 'Local para descansar',
    description:
      'Enquanto você aguarda sua viagem, fique a vontade para descansar em nossa sala VIP.',
  },
  {
    icon: shirtSvg,
    alt: 'Ícone de uma camisa com cabide',
    title: 'Vestiário com chuveiro',
    description:
      'Faça sua viagem de forma tranquila, oferecemos para o seu conforto, chuveiros e vestiários.',
  },
  {
    icon: mugSvg,
    alt: 'Ícone de uma café',
    title: 'Café e água',
    description:
      'Uma cortesia de nossa sala VIP, disponibilizamos água e café para nossos clientes.',
  },
]
