export default [
  {
    name: 'Home',
    slug: '/',
  },
  {
    name: 'Pacotes Turísticos',
    slug: '/pacotes-turisticos/',
  },
  {
    name: 'Viagem de Compras',
    slug: '/viagem-de-compras/',
  },
  {
    name: 'Serviços',
    slug: '/servicos/',
  },
  // {
  //   name: 'Blog',
  //   slug: '/',
  // },
  {
    name: 'Contato',
    slug: '/contato/',
  },
]
