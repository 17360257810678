export default [
  {
    name: 'Home',
    slug: '/',
  },
  {
    name: 'Pacotes turísticos',
    slug: '/pacotes-turisticos/',
  },
  {
    name: 'Viagem de compras',
    slug: '/viagem-de-compras/',
  },
  {
    name: 'Serviços',
    slug: '/servicos/',
    subItems: [],
  },
  // {
  //   name: 'Blog',
  //   slug: '/blog/',
  // },
  {
    name: 'Contato',
    slug: '/contato/',
    subItems: [
      {
        name: 'Trabalhe Conosco',
        slug: 'https://platform.senior.com.br/hcmrs/hcm/curriculo/?tenant=bauerexpress&tenantdomain=bauerexpress#!/vacancies/list',
        external: true,
      },
      {
        name: 'Portal do Colaborador',
        slug: 'https://platform.senior.com.br/login/?redirectTo=https%3A%2F%2Fplatform.senior.com.br%2Fsenior-x%2F&tenant=bauerexpress.com.br',
        external: true,
      },
    ],
  },
]
